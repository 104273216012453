<script>

export default {
  mixins: [],
  components: {},
  data() {
    return {
      applicationEnvironment: process.env.VUE_APP_ENVIRONMENT,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logOut");
    }
  },
  mounted() {
  },
}
</script>

<template>

  <header class="app-header top-bar">
    <nav class="navbar navbar-expand-lg">
      <div class="navbar-header align-items-center d-lg-none d-block">
        <a class="mobile-toggle"><i class="ti ti-align-right"/></a>
        <RouterLink class="navbar-brand" :to="{name : 'home'}">
          <img alt="logo" class="img-fluid logo-desktop-top" src="@assets/logo_min.svg">
        </RouterLink>
      </div>
      <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
        <i class="ti ti-align-left"/>
      </button>
      <!-- end navbar-header -->
      <!-- begin navigation -->
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <div class="navigation d-flex justify-content-between">
          <div class="search-box  mr-3 d-none d-xl-block align-items-center">
            <span class="test-env-alert" v-if="applicationEnvironment !== 'prod'">
              <i class="fe fe-alert-triangle mr-1" style="font-size: 1rem" />Vous êtes sur la base test</span>
          </div>
          <ul class="navbar-nav nav-right ml-auto ml-xl-0">
            <li class="nav-item dropdown user-profile d-flex">
              <a id="navbarDropdown4" aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle d-flex"
                 data-toggle="dropdown" href="javascript:void(0)" role="button">
                <div class="d-flex align-items-center text-left">
                  <div class="mr-2">
                    <h6 class="mb-0">{{ this.$store.getters.username }}</h6>
                  </div>
                  <div class="position-relative">
                    <!--<span class="bg-success user-status"></span>-->
                  </div>

                </div>
              </a>
              <div aria-labelledby="navbarDropdown" class="dropdown-menu animated fadeIn">
                <div class="px-4 py-3 bg-holder bg-overlay-primary-70">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-1">
                      <h5 class="text-white mb-0">{{ this.$store.getters.username }}</h5>
                      <p><small class="text-white">{{ this.$store.state.userInformation.email }}</small></p>
                      <p><small class="text-white">{{ this.$store.state.userInformation.userGroupName }}</small></p>
                    </div>
                    <a class="text-white f-22 tooltip-wrapper pointer" data-original-title="Logout"
                       data-placement="top"
                       data-toggle="tooltip" title="" @click="logout"><i
                        class="zmdi zmdi-power"/>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end navigation -->
    </nav>
  </header>

</template>